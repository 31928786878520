<template>
  <SourcesBrowse></SourcesBrowse>
</template>

<script>
import SourcesBrowse from "@/components/newSources/SourcesBrowse";
export default {
  name: "Browse",
  components: { SourcesBrowse }
};
</script>

<style scoped></style>
